<template>
  <FeedLayoutComponent />
</template>

<script>
const FeedLayoutComponent = () => import('@/components/feed/FeedLayoutComponent');

import { mapActions } from "vuex";
export default {
  name: 'Home',
  components: { FeedLayoutComponent },
  data: () => ({
    drawer: null,
    initCountOfUpdateSpecialties: 0
  }),
  mounted() {
    this.hideTopMenu(false);
    this.setTopMenuTitle('');
  },
  methods: {
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
  },
}
</script>

<style scoped>
.layout-container {
  background-color: var(--v-background-base);
  height: 100% !important;
  padding: 0px !important;
}

.input-search {
  padding-top: 23px!important;
}

.clickable:hover {
  cursor: pointer;
}
</style>
